import { launchViewer, filterModelByTaskBraskem } from './ViewerFunction';
import React, { useEffect } from 'react';

//const NOP_VIEWER = window.NOP_VIEWER;  

export function ViewerBraskem({
  selectedContract,
  defaultTasks,
  selectedTasks,
  selectedType,
  extraData
}) {
  useEffect(() => {

    if (defaultTasks) {
      filterModelByTaskBraskem(
        defaultTasks,
        selectedTasks,
        selectedType,
        extraData
          ? extraData.map((item) => ({
              ...item,
              MaiorBaseline: new Date(item?.MaiorBaseline),
              MaiorTimephased: new Date(item?.MaiorTimephased)
            }))
          : []
      );
    }
  }, [defaultTasks, selectedTasks, selectedType, extraData]);

  useEffect(() => {
    launchViewer('viewerDiv', selectedContract);
  }, [selectedContract]);

  return <div style={{ position: 'relative', width: '100%', height: '100%' }} id="viewerDiv" />;
}
