import React, { useMemo } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis
} from 'recharts';
import { Spinner } from '../Spinner';
import { cn } from '../../lib/utils';

function BarChartNew({ label, type, data, isLoading, color }) {
  const chartData = useMemo(() => {
    if (!data) {
      return [];
    }

    if (type === 'QC') {
      const chartData = data.map((item) => ({
        name: `${item.MESES.split('-')[1]}/${item.MESES.split('-')[0]}`,
        Checklists: item.QTDS_CHECKLISTS,
        'Não Conformes': item.QTDS_PLANOACAO,
        Conformes: item.QTDS_CONFORMES
      }));

      return chartData;
    }

    if (type === 'NCPT') {
      const chartData = data.map((item) => ({
        name: item.CHECKLIST.split(' - ')[0],
        'Não Conformes': item.QTDS_PLANOACAO,
        Conformes: item.QTDS_CONFORMES,
        value: item.CHECKLIST
      }));

      return chartData;
    }
  }, [data, type]);
  return (
    <div className={cn('rounded w-full p-2 flex flex-col gap-2 min-h-[300px]', {
      'bg-[#003C7F]': color === "braskem",
      'bg-[#313862]': color !== "braskem",
    })}>
      <h3 className="text-base font-medium text-center text-white">{label}</h3>

      {isLoading ? (
        <div className="w-full h-[300px] flex justify-center items-center">
          <Spinner className="text-white w-20 h-20 flex" />
        </div>
      ) : (
        <ResponsiveContainer height={type !== 'NCPT' ? 300 : '100%'} width="100%">
          <BarChart data={chartData}>
            <CartesianGrid horizontal={false} strokeDasharray="1 1" />

            <XAxis stroke="white" dataKey="name" />
            {type === 'NCPT' && <XAxis stroke="white" dataKey="name" />}

            <Legend />
            {type === 'QC' && (
              <>
                {/* <Bar barSize={20} dataKey="Checklists" fill="#a1caeb" /> */}
                <Bar barSize={20} dataKey="Conformes" fill="#62C17E" />
                <Bar barSize={20} dataKey="Não Conformes" fill="#ff0000" />
                <Tooltip cursor={{ fill: 'transparent' }} />
              </>
            )}
            {type === 'NCPT' && (
              <>
                {/* <Bar barSize={10} dataKey="Checklists" fill="#a1caeb" /> */}
                <Bar barSize={10} dataKey="Conformes" fill="#62C17E" />
                <Bar barSize={10} dataKey="Não Conformes" fill="#ff0000" />
                <Tooltip
                  cursor={{ fill: 'transparent' }}
                  labelFormatter={(a, b, c) => {
                    return <span className="text-black z-50">{b[0]?.payload?.value}</span>;
                  }}
                />
              </>
            )}
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
}

export default BarChartNew;
