import { Minus, Plus } from 'lucide-react';
import { cn } from '../lib/utils';
import Tooltip from '@mui/material/Tooltip';

function formatDate(dateString) {
  if (!dateString) return 'Nula';
  try {
    // Adiciona 'Z' ao final da string de data para indicar UTC
    const date = new Date(dateString);
    // Converte a data para o fuso horário local e formata para 'dd/MM/yyyy'
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1; // Meses começam do zero
    const year = date.getUTCFullYear();

    // Formata o dia e o mês para dois dígitos
    const formattedDay = String(day).padStart(2, '0');
    const formattedMonth = String(month).padStart(2, '0');

    return `${formattedDay}/${formattedMonth}/${year}`;
  } catch (error) {
    return 'Nula';
  }
}

function getFormattedData(task) {
  const terminoEstimado = task.AssignmentFinishDate
    ? new Date(task.AssignmentFinishDate)
    : null;

  const terminoLinhaDeBase = task.terminoLinhaDeBase
    ? new Date(task.terminoLinhaDeBase)
    : null;

  const totalDias = task?.TotalDias ?? null;

  // Calcular desvio em dias
  const desvioDias = terminoEstimado && terminoLinhaDeBase
    ? Math.ceil(
        (terminoEstimado.getTime() - terminoLinhaDeBase.getTime()) / (1000 * 60 * 60 * 24) // Conversão de milissegundos para dias
      ) - 1
    : '0';

  // Calcular desvio percentual
  const desvioPercentual = totalDias
    ? (((desvioDias) / (totalDias)) * 100).toFixed(2)
    : '0';
    
  return {
    previsto: (task?.Previsto * 100)?.toFixed(2) ?? 0,
    realizado: (task?.Realizado * 100)?.toFixed(2) ?? 0,
    terminoEstimado: task.AssignmentFinishDate
      ? formatDate(task.AssignmentFinishDate)
      : 'Nula',
    terminoLinhaDeBase: task.terminoLinhaDeBase
      ? formatDate(task.terminoLinhaDeBase)
      : 'Nula',
    desvio: desvioPercentual,
    desvioDias: desvioDias,
  };
}

//export function TaskTree({ tasks, openBranches, onToggleBranch, setSelectedTask, selectedTask, dynamicColor }) {
export function TaskTree({ tasks, openBranches, onToggleBranch, selectedTask, dynamicColor }) {
  function handleOpenTaskChildren(task) {
    if (task.children.length === 0) {
      return;
    }

    onToggleBranch(task.TaskId);
  }

  return (
    <ul className={`flex flex-col min-w-[900px] bg-${dynamicColor}`}> 
      {tasks.map((task) => (
        <li key={task.TaskId} className="flex flex-col rounded-md">
          <div
            //onClick={() => setSelectedTask(task)}
            className={cn(
              'grid grid-cols-[400px_1fr_1fr_1fr_1fr_1fr_80px] py-2 gap-2 hover:bg-blue-950/60 px-2',
              selectedTask &&
                selectedTask.TaskId === task.TaskId &&
                `bg-${dynamicColor}-950/60 hover:bg-${dynamicColor}-950/90 rounded-md`,
              selectedTask &&
                task.TaskOutlineNumber.startsWith(selectedTask.TaskOutlineNumber) &&
                `bg-${dynamicColor}-950/60 hover:bg-${dynamicColor}-950/90 rounded-md`
            )}>
            <div
              style={{
                marginLeft: task.TaskOutlineNumber.match(/\./g)?.length
                  ? task.TaskOutlineNumber.match(/\./g)?.length * 10
                  : 0
              }}
              className="flex items-center gap-2">
              <Tooltip
                title={
                  task.children.length !== 0 ? task.children.length + " itens" : null
                }>
                <button
                  onClick={(event) => {
                    handleOpenTaskChildren(task);
                    event.stopPropagation();
                  }}
                  className={`w-4 h-4 flex items-center justify-center rounded-md border border-slate-400 hover:border-slate-800 ${
                    task.children.length === 0 ? 'invisible' : ''
                  }`}>
                  {openBranches.includes(task.TaskId) ? <Minus size={8} /> : <Plus size={8} />}
                </button>
              </Tooltip>
              <span
                className={cn(
                  'text-sm text-white',
                  task.TaskOutlineLevel === 1 && 'font-semibold'
                )}>
                {task.TaskName}
              </span>
            </div>

            <span className="text-sm text-white">{getFormattedData(task).previsto}%</span>
            <span className="text-sm text-white">{getFormattedData(task).realizado}%</span>
            <span className="text-sm text-white">{getFormattedData(task).terminoEstimado}</span>
            <span className="text-sm text-white">{getFormattedData(task).terminoLinhaDeBase}</span>
            <span className="text-sm text-white">{getFormattedData(task).desvio ?? 0}%</span>
            <span className="text-sm text-white">{getFormattedData(task).desvioDias}</span>
          </div>

          {openBranches.includes(task.TaskId) && (
            <TaskTree
              tasks={task.children}
              openBranches={openBranches}
              onToggleBranch={onToggleBranch}
              //setSelectedTask={setSelectedTask}
              selectedTask={selectedTask}
            />
          )}
        </li>
      ))}
    </ul>
  );
}
