import { Avatar, CardContent, Stack, Typography } from '@mui/material';
import Calendar from '../../assets/icons/calendario.png';
import { cn } from '../../lib/utils';
import { Spinner } from '../Spinner';

export function IconCard({ label, value, icon, className, isLoading }) { 

  const fontSize = 'md:text-sm 2xl:text-[15px]';

  return (
    <div
      className={cn(
        'text-white rounded h-full',
        isLoading && 'items-center justify-center h-[72px] flex',
        className
      )}>

        <CardContent>
          {isLoading ? (
            <Spinner />
          ) : (
            
              <Stack alignItems="flex-start" direction="row" justifyContent="space-between" spacing={1}>
                <Stack spacing={1}>
                  <Typography gutterBottom variant="overline">
                    {label}
                  </Typography>
                  {label === 'Prazo de Vigência' ? (
                    <h2 className={cn('font-bold uppercase mt-[17px] ', fontSize)}>{value.length > 0 ? value : 'Indefinido'}</h2>
                  ) : (
                    <h2 className={cn('font-bold uppercase mt-[17px] ', fontSize)}>{value}</h2>
                  )}
                </Stack>
                <Avatar
                  sx={{
                    backgroundColor: '#62C17E',
                    height: 56,
                    width: 56,
                  }}>
                  {label === 'Prazo de Vigência' ? (
                    <img src={Calendar} alt="s" style={{ width: '35px' }} />
                  ) : label === 'Descrição' ? (
                    <img src={icon} alt="s" style={{ width: '40px' }} />
                  ) : (
                    <img src={icon} alt="s" style={{ width: '50px' }} />
                  )}
                </Avatar>
              </Stack>
          )}
        </CardContent>

    </div>
  );
}

