import React, { useEffect, useState, useCallback } from 'react';

import { v4 as uuidv4 } from 'uuid';

import { api } from '../../../lib/axios';

import { BraskemHeader } from '../BraskemHeader';
import iconMoeda from '../../../assets/icons/moeda.png';

import TabelaBms from '../../../Components/Tabelas/TabelaBMs';
import TabelaServicosSetor from '../../../Components/Tabelas/ServicosBMs';
import BarChart from '../../../Components/Charts/BarChart';
import Viewer from '../../../Components/Viewer/Viewer';
import { AvancoFinanceiroCard } from '../../../Components/Cards/AvancoFinanceiroCard';
import { IconCard } from '../../../Components/Cards/IconCard';
import { Select } from '../../../Components/Select';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../../Components/Tabs';
import { Spinner } from '../../../Components/Spinner';

import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';

function FinanceiroBraskem() {

  const [dadosContrato, setDadosContrato] = useState({
    //Controladores dos filtros
    opcoesContrato: [],
    selectedContrato: '',
    opcoesSetores: [],
    selectedSetores: [],
    opcoesBMs: [],
    selectedBM: '',
    //Setores para o 3D Viewer
    setorComProgresso: [],
    //Dados gerais
    dataVigencia: [],
    cardsSuperiores: [],
    valorPrevistoAcumulado: [],
    graficoPrevistoXExecutado: [],
    tabelaBms: [],
    tabelaServicos: [],
    // Estados
    mobileOpen: false,
    loading: true,
  });

  const { opcoesContrato, selectedContrato, opcoesSetores, selectedSetores, opcoesBMs, selectedBM, setorComProgresso, dataVigencia, cardsSuperiores, valorPrevistoAcumulado, graficoPrevistoXExecutado, tabelaBms, tabelaServicos, mobileOpen, loading } = dadosContrato;

  // Atualizar o estado
  const setOpcoesContrato = (items) => setDadosContrato(prevState => ({ ...prevState, opcoesContrato: items }));
  const setSelectedContrato = (item) => setDadosContrato(prevState => ({ ...prevState, selectedContrato: item }));
  const setOpcoesSetores = (items) => setDadosContrato(prevState => ({ ...prevState, opcoesSetores: items }));
  const setSelectedSetores = (items) => setDadosContrato(prevState => ({ ...prevState, selectedSetores: items }));
  const setOpcoesBMs = (items) => setDadosContrato(prevState => ({ ...prevState, opcoesBMs: items }));
  const setSelectedBM = (item) => setDadosContrato(prevState => ({ ...prevState, selectedBM: item }));
  
  const setSetorComProgresso = (items) => setDadosContrato(prevState => ({ ...prevState, setorComProgresso: items }));
  const setDataVigencia = (items) => setDadosContrato(prevState => ({ ...prevState, dataVigencia: items }));
  const setCardsSuperiores = (items) => setDadosContrato(prevState => ({ ...prevState, cardsSuperiores: items }));
  const setValorPrevistoAcumulado = (items) => setDadosContrato(prevState => ({ ...prevState, valorPrevistoAcumulado: items }));
  const setGraficoPrevistoXExecutado = (items) => setDadosContrato(prevState => ({ ...prevState, graficoPrevistoXExecutado: items }));
  const setTabelaBMs = (items) => setDadosContrato(prevState => ({ ...prevState, tabelaBms: items }));
  const setTabelaServicos = (items) => setDadosContrato(prevState => ({ ...prevState, tabelaServicos: items }));
 
  const setMobileOpen = (isOpen) => setDadosContrato(prevState => ({ ...prevState, mobileOpen: isOpen }));
  const setLoading = (isLoading) => setDadosContrato(prevState => ({ ...prevState, loading: isLoading }));

  //Fetch informações do backend
  const fetchDataViewer = useCallback(async (optionContrato, optionBM, optionSetor) => {
    setSetorComProgresso([]);

    let setoresSelecionados = optionSetor;

    if (optionSetor && optionSetor.length > 0) {
      if (optionSetor[0].Codigo_WBS) {
        setoresSelecionados = optionSetor.map(objeto => objeto.Codigo_WBS);
      }
    } else {
      setoresSelecionados = opcoesSetores.map(objeto => objeto.Codigo_WBS);
    }

    try {
      const responses = await Promise.all(setoresSelecionados.map(codSetor =>
        api.get(`braskem/dadosSetoresViewerFN?numContrato=${optionContrato}&setores=${codSetor}&bm=${optionBM}&instancia=braskem`)
      ));

      const newSetores = responses.reduce((acc, response) => {
        if (response.data && response.data.length > 0) {
          const existsInArray = acc.findIndex(item => item.Codigo_WBS === response.data[0].Codigo_WBS);
          if (existsInArray === -1) {
            acc.push(response.data[0]);
          }
        }
        return acc;
      }, []);

      setDadosContrato(prevState => {
        const currentSetorComProgresso = prevState.setorComProgresso;
        const updatedSetores = [...currentSetorComProgresso, ...newSetores];
        return { ...prevState, setorComProgresso: updatedSetores };
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [opcoesSetores]);

  const fetchDataControladores = useCallback(async (optionContrato) => {
    try {
      const [setorResponse, bmResponse, vigenciaResponse, chartDataResponse] = await Promise.all([
        api.get(`braskem/opcoesSetoresFN?numContrato=${optionContrato}&instancia=braskem`),
        api.get(`braskem/opcoesBMsFN?numContrato=${optionContrato}&instancia=braskem`),
        api.get(`braskem/dadosDataVigenciaFN?numContrato=${optionContrato}&instancia=braskem`),
        api.get(`braskem/dadosGraficoFN?numContrato=${optionContrato}&instancia=braskem`)
      ]);

      setOpcoesSetores(setorResponse.data);
      setOpcoesBMs(bmResponse.data);
      setDataVigencia(vigenciaResponse.data);
      setGraficoPrevistoXExecutado(chartDataResponse.data);

      fetchDataViewer(optionContrato, selectedBM, setorResponse.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false)
    }
  }, [fetchDataViewer, selectedBM]);
  
  const fetchDataInformacoes = useCallback(async (optionContrato, optionBM, optionSetor) => {
    try {
      const [tabelaBMResponse, valorPrevistoAcumuladoResponse, tabelaServicosResponse] = await Promise.all([
        api.get(`braskem/dadosTabelaBMsFN?numContrato=${optionContrato}&setor=&bm=${optionBM}&instancia=braskem`),
        api.get(`braskem/dadosValorPrevistoAcumuladoFN?numContrato=${optionContrato}&setor=&bm=${optionBM}&instancia=braskem`),
        api.get(`braskem/dadosTabelaServicosFN?numContrato=${optionContrato}&setor=${optionSetor}&instancia=braskem`)
      ]);

      setTabelaBMs(tabelaBMResponse.data);
      setCardsSuperiores(tabelaBMResponse.data.length > 0 ? [tabelaBMResponse.data[tabelaBMResponse.data.length - 1]] : tabelaBMResponse.data);
      setValorPrevistoAcumulado(valorPrevistoAcumuladoResponse.data);
      setTabelaServicos(tabelaServicosResponse.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false)
    }
  }, []);

  //HandleChange dos filtros
  const handleChangeContrato = useCallback((event) => {
    setLoading(true)
    setSelectedContrato(event.target.value);
    setMobileOpen(false);
  
    const optionContrato = event.target.value.split('/')[1];

    setSelectedSetores([])
    setSelectedBM('')
  
    fetchDataControladores(optionContrato);
    fetchDataInformacoes(optionContrato, "", []);
  }, [fetchDataControladores, fetchDataInformacoes]); 

  const handleChangeBM = useCallback((event) => {
    setLoading(true)
    setSelectedBM(event.target.value);
    setMobileOpen(false);

    const optionBM = event.target.value;

    fetchDataInformacoes(selectedContrato.split('/')[1], optionBM, selectedSetores);
    fetchDataViewer(selectedContrato.split('/')[1], optionBM, selectedSetores);
    
  }, [selectedContrato, selectedSetores, fetchDataInformacoes, fetchDataViewer]); 

  const handleChangeSetor = useCallback((event) => {
    setLoading(true)
    setSelectedSetores(event.target.value)
    setMobileOpen(false);

    const optionSetor = event.target.value;

    fetchDataInformacoes(selectedContrato.split('/')[1], selectedBM, optionSetor);
    fetchDataViewer(selectedContrato.split('/')[1], selectedBM, optionSetor)

  }, [selectedContrato, selectedBM, fetchDataInformacoes, fetchDataViewer]); 

  //useEffect inicial, carrega os contratos, seleciona o primeiro contrato
  useEffect(() => {
    api
      .get('/token/listObjectsPBB?instancia=braskem')
      .then((response) => {
        const contratos = response.data.items;
        setOpcoesContrato(contratos);
        if (contratos.length > 0) {
          const primeiroContratoId = contratos[0].objectId;
          setSelectedContrato(primeiroContratoId);
          handleChangeContrato({ target: { value: primeiroContratoId } });
        }
      });
  }, []); 

  return (
    <div className="bg-braskemPrimary min-h-screen">

      <BraskemHeader menuOpen={mobileOpen} setMenuOpen={setMobileOpen}>  
        {/* Filtro de Setor */}
        <Select
          className="min-w-[250px] max-w-[350px]"
          label="Setor"
          onChange={handleChangeSetor}
          value={selectedSetores}
          renderValue={(selected) => selected.join(', ')}
          multiple
        >
          {opcoesSetores.map(option => {
            const indent = ' '.repeat((option.Codigo_WBS.length - 7) / 3);
            const discriminacao = option.Discriminacao_WBS.length > 25 
              ? `${option.Discriminacao_WBS.slice(0, 25)}...` 
              : option.Discriminacao_WBS;
            
            return (
              <MenuItem
                key={option.Codigo_WBS}
                value={option.Codigo_WBS}
                style={{ display: 'flex', alignItems: 'center', padding: '4px 8px' }}
              >
                <Checkbox 
                  checked={selectedSetores.includes(option.Codigo_WBS)} 
                  style={{ padding: '0 8px' }} 
                />
                <Tooltip title={option.Discriminacao_WBS}>
                  <ListItemText
                    primary={<span style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{`${indent}${discriminacao}`}</span>}
                    style={{ marginLeft: '8px' }}
                  />
                </Tooltip>
              </MenuItem>
            );
          })}
        </Select>
        {/* Filtro de BM */}
        <Select
          className="min-w-[200px] max-w-[250px]"
          label="BM"
          onChange={handleChangeBM}
          value={selectedBM}
        > 
          <MenuItem key={uuidv4()} label={'Todos'} value={''}>
            {'Todos'}
          </MenuItem>
          {(opcoesBMs.map((opcao) => (
            <MenuItem key={opcao.BMSP} label={opcao.BMSP} value={opcao.BMSP}>
              {opcao.BMSP}
            </MenuItem>
          )))}
        </Select>
        {/* Filtro de Contrato */}
        <Select
          className="min-w-[200px] max-w-[250px]"
          label="Contrato"
          onChange={handleChangeContrato}
          value={selectedContrato}
        > 
          {opcoesContrato?.map((opcao) => (
            <MenuItem
              key={opcao?.objectId}
              label={opcao?.objectKey.slice(0, 8)}
              value={opcao?.objectId}>
              {opcao?.objectKey.slice(0, opcao.objectKey.length - 4)}
            </MenuItem>
          ))}
        </Select>
      </BraskemHeader>

      <div className="mt-16 p-4 space-y-4">

        {/* Linha 1 - Cards superiores */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4">

          <div className="col-span-1">
            <IconCard
              value={
                    dataVigencia.map(
                    (opcao) =>
                      `${new Date(opcao.Data_de_Inicio)
                        .toLocaleDateString('pt-BR')
                        .toString()} A ${new Date(opcao.Data_de_Termino_Atual_Vigencia)
                        .toLocaleDateString('pt-BR')
                        .toString()}`
                  )}
              label="Prazo de Vigência"
              icon={iconMoeda}
              className={'bg-braskemSecondary'}
              isLoading={loading}
            /> 
          </div>

          <div className="col-span-1">
              <IconCard
                value={
                  `R$ ` +
                  cardsSuperiores.map((opcao) =>
                    new Intl.NumberFormat('pt-BR').format(opcao.ValorPrevisto_WBS)
                  )
                }
                label="Valor Atual do Contrato"
                className={'bg-braskemSecondary'}
                icon={iconMoeda}
                isLoading={loading}
              />
          </div>

          <div className="col-span-1">
              <IconCard
                value={
                  `R$ ` +
                  valorPrevistoAcumulado.map((opcao) =>
                    new Intl.NumberFormat('pt-BR').format(opcao.Total_Previsto_Ate_Atual)
                  )
                }
                label="Valor Previsto Acumulado"
                className={'bg-braskemSecondary'}
                icon={iconMoeda}
                isLoading={loading}
              />
          </div>

          <div className="col-span-1">
              <IconCard
                value={
                  `R$ ` +
                  cardsSuperiores.map((opcao) =>
                    new Intl.NumberFormat('pt-BR').format(opcao.ValorAcumulado_WBS)
                  )
                }
                label="Valor Medido Acumulado"
                className={'bg-braskemSecondary'}
                colorIcon="#62C17E"
                icon={iconMoeda}
                isLoading={loading}
              />
          </div>

          <div className="col-span-1">
              <AvancoFinanceiroCard
                value={cardsSuperiores}
                label={'Avanço Financeiro'}
                className={'bg-braskemSecondary'}
                isLoading={loading}
              />
          </div>

        </div>

        {/* Linha 2 - Viewer e gráfico */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-11 gap-4">

          {/* Viewer */}
          <div className="items-center justify-center text-white col-span-7 flex flex-col gap-6 h-[480px] lg:h-full bg-braskemSecondary p-2 text-center rounded">
              <Viewer selectedContract={selectedContrato} selectedSetores={setorComProgresso} />
              {/* Legenda do Viewer */}
              <div className="flex justify-center items-center gap-4 pb-1">
                <div className="flex items-center gap-2">
                  <div className="w-4 h-4 bg-cardVerde"></div>
                    <span className="text-sm text-cardVerde">Concluído</span>
                </div>
                <div className="flex items-center gap-2">
                  <div className="w-4 h-4 bg-cardAmarelo"></div>
                  <span className="text-sm text-cardAmarelo">Em andamento</span>
                </div>
                <div className="flex items-center gap-2">
                  <div className="w-4 h-4 bg-cardVermelho"></div>
                  <span className="text-sm text-cardVermelho">A iniciar</span>
                </div>
              </div>
          </div>

          {/* Gráfico Previsto x Executado */}
          <div className="items-center justify-center flex text-white col-span-4 bg-braskemSecondary p-2 text-center rounded" style={{ height: '65vh' }}>
            {loading ? (
              <Spinner />
            ) : (
              <BarChart 
                dados={graficoPrevistoXExecutado}
                altura={'60vh'}
                cabecalho={'Previsto x Realizado - Execução Financeira'}
                modulo={'F'}
                eixo={'x'}
              />
            )}
          </div>

        </div>

        {/* Linha 3 - Tabelas */}
        <div className={'grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4'}>
           <Tabs defaultValue="bms">

            <TabsList className="bg-braskemSecondary">
              <TabsTrigger className="data-[state=active]:bg-braskemPrimary" value="bms">BMs</TabsTrigger>
              <TabsTrigger className="data-[state=active]:bg-braskemPrimary" value="servicos">Serviços</TabsTrigger>
            </TabsList>

              <div className="items-center justify-center text-white col-span-1 rounded">

                <TabsContent value="bms" className="bg-braskemSecondary p-2 text-center rounded">
                  {loading ? (
                    <div className="h-48 w-full grid place-items-center">
                      <Spinner className="m-auto"/>
                    </div>
                  ) : (
                    <TabelaBms altura={'300px'} dados={tabelaBms} />
                  )}  
                </TabsContent>

                <TabsContent value="servicos" className="bg-braskemSecondary p-2 text-center rounded">
                  {loading ? (
                    <div className="h-48 w-full grid place-items-center">
                      <Spinner className="m-auto"/>
                    </div>
                  ) : (
                    <TabelaServicosSetor
                      altura={'300px'}
                      dados={ selectedBM? tabelaServicos?.filter((item) =>(item?.BMSP === selectedBM)) : tabelaServicos}
                    />
                  )}   
                </TabsContent>

              </div>

            </Tabs>

           
        </div>

      </div>
    </div>
  );
}

export { FinanceiroBraskem };
