import React, { useState, useEffect, useCallback } from 'react'; 
import { api } from '../../../lib/axios';
import { MenuItem } from '@mui/material';
import { createTaskTree, flattenTasks, updateAssignmentFinishDates } from '../../../utils/group-by';

import { APMHeader } from '../APMHeader';
import { SimpleCard } from '../../../Components/Cards/SimpleCard';

import { ViewerAPM } from '../../../Components/Viewer/ViewerAPM';

import { Select } from '../../../Components/Select';
import { BarLineChart } from '../../../Components/BarLineChart';
import { TaskTree } from '../../../Components/TaskTree';
import { Spinner } from '../../../Components/Spinner';

export default function FisicoAPM() {

  const [dadosContrato, setDadosContrato] = useState({
    //Controladores dos filtros
    opcoesContrato: [],
    selectedContrato: '',
    //Dados gerais
    selectedTarefa: '',
    cardsSuperiores: [],
    graficoBarras: [],
    cronogramaViewer: [],
    tabelaTarefas: [],
    // Estados
    mobileOpen: false,
    selectedAba: 'avanco',
    openBranches: [],
    loading: true,
  });

  const { opcoesContrato, selectedContrato, selectedTarefa, cardsSuperiores, graficoBarras, cronogramaViewer, tabelaTarefas, mobileOpen, selectedAba, openBranches, loading } = dadosContrato;

  // Atualizar o estado
  const setOpcoesContrato = (items) => setDadosContrato(prevState => ({ ...prevState, opcoesContrato: items }));
  const setSelectedContrato = (contract) => setDadosContrato(prevState => ({ ...prevState, selectedContrato: contract }));
  const setSelectedTarefa = (task) => setDadosContrato(prevState => ({ ...prevState, selectedTarefa: task }));
  const setCardsSuperiores = (item) => setDadosContrato(prevState => ({ ...prevState, cardsSuperiores: item }));
  const setGraficoBarras = (item) => setDadosContrato(prevState => ({ ...prevState, graficoBarras: item }));
  const setCronogramaViewer = (item) => setDadosContrato(prevState => ({ ...prevState, cronogramaViewer: item }));
  const setTabelaTarefas = (item) => setDadosContrato(prevState => ({ ...prevState, tabelaTarefas: item }));
  const setMobileOpen = (isOpen) => setDadosContrato(prevState => ({ ...prevState, mobileOpen: isOpen }));
  const setSelectedAba = (type) => setDadosContrato(prevState => ({ ...prevState, selectedAba: type }));
  const setOpenBranches = (branches) => setDadosContrato(prevState => ({ ...prevState, openBranches: branches }));
  const setLoading = (isLoading) => setDadosContrato(prevState => ({ ...prevState, loading: isLoading }));
  
  // Alternar o estado de um ramo aberto
  function toggleBranch(taskId) {
    if (openBranches.includes(taskId)) {
      setOpenBranches(openBranches.filter((id) => id !== taskId));
    } else {
      setOpenBranches([...openBranches, taskId]);
    }
  }

  const fetchDataInformacoes = useCallback(async (optionContrato) => {
    try {
      const [cardsSuperioresResponse, graficoBarrasResponse, cronogramaViewerResponse, tabelaTarefasResponse] = await Promise.all([
        api.get(`/apm/dadosCardsSuperioresFS?numContrato=${optionContrato}`),
        // api.get(`/apm/dadosGraficoBarrasFS?numContrato=${optionContrato}&taskNumber=${selectedTarefa.TaskOutlineNumber}`),
        api.get(`/apm/dadosGraficoBarrasFS?numContrato=${optionContrato}`),
        api.get(`/apm/dadosCronogramaViewerFS?numContrato=${optionContrato}`),
        api.get(`/apm/dadosTarefasFS?numContrato=${optionContrato}`)
      ]);
      setCardsSuperiores(cardsSuperioresResponse.data);
      setGraficoBarras(graficoBarrasResponse.data);
      setCronogramaViewer(cronogramaViewerResponse.data);
      // Lista de tarefas em formato de árvore para ser utilizado na tabela
      const taskTree = createTaskTree(
        tabelaTarefasResponse.data.map((item) => ({
          ...item,
          TaskOutlineLevel: Number(item.TaskOutlineLevel),
          AssignmentFinishDate: item.AssignmentFinishDate ? item.AssignmentFinishDate : null
        }))
      );
      // Retorna tanto a lista de tarefas em formato de árvore quanto a lista de tarefas normal
      setTabelaTarefas({
        taskTree,
        full: tabelaTarefasResponse.data.map((item) => ({
          ...item,
          TaskOutlineLevel: Number(item.TaskOutlineLevel),
          AssignmentFinishDate: item.AssignmentFinishDate ? item.AssignmentFinishDate : null
        }))
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false)
    }
  }, []);

  const handleChangeContrato = useCallback((event) => {
    setLoading(true)
    setSelectedContrato(event.target.value);
    setMobileOpen(false);
  
    const optionContrato = event.target.value.split('/')[1].slice(0, -4);
  
    fetchDataInformacoes(optionContrato);
  }, [fetchDataInformacoes]); 

    const handleChangeTarefa = useCallback((event) => {
    setLoading(true)
    if (selectedTarefa && selectedTarefa.TaskId === event.TaskId) {
      setSelectedTarefa(null);
    } else {
      // setSelectedTarefa(event);
      setSelectedTarefa(null);
    }
    fetchDataInformacoes(selectedContrato.split('/')[1]);
  }, [fetchDataInformacoes, selectedContrato, selectedTarefa]); 

    //useEffect inicial, carrega os contratos, seleciona o primeiro contrato
    useEffect(() => {
      api
        .get('/token/listObjectsPBB?instancia=apm')
        .then((response) => {
          const contratos = response.data.items;
          setOpcoesContrato(contratos);
          if (contratos.length > 0) {
            const primeiroContratoId = contratos[0].objectId;
            setSelectedContrato(primeiroContratoId);
            handleChangeContrato({ target: { value: primeiroContratoId } });
          }
        });
    }, []); 

  // Atualiza as datas de término para ficar de acordo com a lógica (tarefas pais tem a data de term. igual a maior data de term. dos filhos)
  updateAssignmentFinishDates(tabelaTarefas?.taskTree);
  // Lista de tarefas selecionadas em formato de tabela (sem ser formato de árvore)
  const flattenedTasks = flattenTasks(selectedTarefa);

  function compare(a, b) {
    const partsA = a.TaskName.split('.').map(part => parseFloat(part));
    const partsB = b.TaskName.split('.').map(part => parseFloat(part));
  
    const maxLength = Math.max(partsA.length, partsB.length);
    
    for (let i = 0; i < maxLength; i++) {
      const partA = partsA[i] || 0;
      const partB = partsB[i] || 0;
  
      if (partA !== partB) {
        return partA - partB;
      }
    }
    return 0;
  }

  return (
    <div className="bg-apmPrimary min-h-screen">

      <APMHeader menuOpen={mobileOpen} setMenuOpen={setMobileOpen}>
        <Select
          className="min-w-[200px] max-w-[250px]"
          label="Contrato"
          onChange={handleChangeContrato}
          value={selectedContrato}
        >
          {opcoesContrato?.map((opcao) => (
            <MenuItem
              key={opcao?.objectId}
              label={opcao?.objectKey.slice(0, 8)}
              value={opcao?.objectId}>
              {opcao?.objectKey.slice(0, opcao.objectKey.length - 4)}
            </MenuItem>
          ))}
        </Select>
      </APMHeader>

      <div className="mt-16 p-4 space-y-4">
        
        {/* Linha 1 - Cards superiores */}
        <div className="grid text-white grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          
          <div className="col-span-1">
            <SimpleCard
              className={'bg-apmSecondary'}
              label="Previsto"
              content={`${cardsSuperiores?.previsto?.toFixed(2) ?? 0}%`}
              isLoading={loading}
              tooltip="Trabalho planejado até hoje dividido pelo total planejado (linha de base)"
            />
          </div>

          <div className="col-span-1">
            <SimpleCard
              className={'bg-apmSecondary'}
              label="Realizado"
              content={`${cardsSuperiores?.realizado?.toFixed(2) ?? 0}%`}
              isLoading={loading}
              tooltip="Trabalho realizado dividido pelo trabalho total"
            />
          </div>

          <div className="col-span-1">
            <SimpleCard
              className={'bg-apmSecondary'}
              label="Desempenho"
              content={`${
                (cardsSuperiores?.realizado / cardsSuperiores?.previsto) * 100
                  ? ((cardsSuperiores?.realizado / cardsSuperiores?.previsto) * 100).toFixed(2)
                  : 0}%`}
              isLoading={loading}
              tooltip="Trabalho realizado dividido pelo previsto"
            />
          </div>

          <div className="col-span-1">
            <SimpleCard
              className={'bg-apmSecondary'}
              label="Diferença tendência (d)"
              content={cardsSuperiores?.diferenciaTendencia ?? 0}
              isLoading={loading}
              tooltip="Diferença em dias entre a data de finalização da linha de base e da projeção atual"
            />
          </div>
        </div>

        {/* Linha 2 - Viewer e gráfico */}        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">

          {/* Viewer */}     
          <div className="col-span-1 border-none bg-apmSecondary p-3 rounded">
              
              {/* Abas */}
              <div className="grid grid-cols-2 bg-apmPrimary p-1 h-10 gap-1 rounded-md mb-2">
                <button
                  className={`w-full text-center text-xs grid place-items-center text-white transition-colors rounded-md ${selectedAba === 'avanco' ? 'bg-apmSecondary' : 'bg-transparent hover:bg-apmSecondary/60'}`}
                  onClick={() => setSelectedAba('avanco')}
                >Avanço Físico</button>
                <button
                  className={`w-full text-center text-xs grid place-items-center text-white transition-colors rounded-md ${selectedAba === 'cronograma' ? 'bg-apmSecondary' : 'bg-transparent hover:bg-apmSecondary/60'}`}
                  onClick={() => setSelectedAba('cronograma')}
                >Cronograma</button>
              </div>

              {/* 3D Viewer */}
              <div className="h-[370px]">
                <ViewerAPM
                  selectedContract={selectedContrato}
                  defaultTasks={tabelaTarefas?.full}
                  selectedTasks={flattenedTasks}
                  selectedType={selectedAba}
                  extraData={cronogramaViewer.data}
                />
              </div> 
              
              {/* Legenda de acordo com a aba selecionada*/}
              <div className="flex justify-center items-center gap-4 mt-3">
                
                <div className="flex items-center gap-2">
                  <div className="w-4 h-4 bg-cardVerde"></div>
                  <span className="text-sm text-cardVerde">Concluído</span>
                </div>
                <div className="flex items-center gap-2">
                  <div className="w-4 h-4 bg-cardAmarelo"></div>
                  <span className="text-sm text-cardAmarelo">{selectedAba === 'avanco'?'Em andamento':'No prazo'}</span>
                </div>
                <div className="flex items-center gap-2">
                  <div className="w-4 h-4 bg-cardVermelho"></div>
                  <span className="text-sm text-cardVermelho">{selectedAba === 'avanco'?'A iniciar':'Em atraso'}</span>
                </div>

              </div>

          </div>

          {/* Gráfico */}     
          <div className={'col-span-1 p-2 bg-apmSecondary border-none rounded min-h-[350px] grid place-items-center'}>
              {loading ? (
                <Spinner className="text-white" />
              ) : (
                <BarLineChart
                  previsto={graficoBarras?.previsto ?? []}
                  realizado={graficoBarras?.realizado ?? []}
                  projecao={graficoBarras?.projecao ?? []}
                  //selectedTarefa={selectedTarefa}
                />
              )}
          </div>
          
        </div>
        
        {/* Linha 3 - Tabela */} 
        <div className="w-full rounded-md overflow-x-auto text-white flex flex-col gap-2 mt-2 bg-apmSecondary">
          <div className="min-w-[900px] grid grid-cols-[400px_1fr_1fr_1fr_1fr_80px] gap-2 py-2 border-b border-slate-300 px-2">
            <span className="text-sm tex">Ação</span>
            {/* <span className="text-sm tex">Previsto</span> */}
            <span className="text-sm tex">Realizado</span>
            <span className="text-sm tex">Término Est.</span>
            <span className="text-sm tex">Término Est. Linha de Base</span>
            <span className="text-sm tex">Desvio (%)</span>
            <span className="text-sm tex">Desvio Dias</span>
          </div>
          {loading ? (
            <div className="h-48 w-full grid place-items-center">
              <Spinner />
            </div>
          ) : (
            <TaskTree
              tasks={tabelaTarefas?.taskTree?.sort(compare) ?? []}
              openBranches={openBranches}
              onToggleBranch={toggleBranch}
              setSelectedTask={handleChangeTarefa}
              selectedTarefa={selectedTarefa}
              dynamicColor={'apmSecondary'}
            />
          )}
        </div>

      </div>

    </div>
  );
}

export { FisicoAPM };