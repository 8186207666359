import React, { useEffect, useState, useCallback } from 'react';

import { v4 as uuidv4 } from 'uuid';

import { api } from '../../../lib/axios';

import { CVIHeader } from '../CVIHeader';
import iconQualidade from '../../../assets/icons/qualidade.png';

import { groupBy } from '../../../utils/group-by';

import { Select } from '../../../Components/Select';
import Card from '../../../Components/Cards/Card';
import { IconCard } from '../../../Components/Cards/IconCard';
import Viewer from '../../../Components/Viewer/ViewerCVI';
import BarChartSimple from '../../../Components/Charts/BarChartSimple';
import { PizzaChart } from '../../../Components/Charts/PizzaChart';
import { TabelaPlanoDeAcao } from '../../../Components/Tabelas/TabelaPlanoDeAcao';
import { TabelaChecklists } from '../../../Components/Tabelas/TabelaChecklists';
import { BarChartTrend } from '../../../Components/Charts/BarChartTrend';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../../Components/Tabs';
import { Spinner } from '../../../Components/Spinner';

import { MenuItem } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';

function QualidadeCVI() {

  const [dadosContrato, setDadosContrato] = useState({
    //Controladores dos filtros
    opcoesContrato: [],
    selectedContrato: '',
    opcoesStatus: [],
    selectedStatus: [],
    opcoesTipo: [],
    selectedTipo: '',
    opcoesSetores: [],
    selectedSetores: [],
    //Dados gerais
    descricao: ["Descrição"],
    cardsSuperiores: [],
    naoConformidadesEmAberto: [],
    graficoBarrasNaoConformidadesPorTipo: [],
    graficoPizza: [],
    graficoBarrasQuantidadeDeChecklists: [],
    tabelaChecklistsAplicadas: [],
    tabelaNaoConformidades: [],
    infoViewer: [],
    // Estados
    mobileOpen: false,
    loading: true,
  });
  
  const { opcoesContrato, selectedContrato, opcoesStatus, selectedStatus, opcoesTipo, selectedTipo, opcoesSetores, selectedSetores, descricao, cardsSuperiores, naoConformidadesEmAberto, graficoBarrasNaoConformidadesPorTipo, graficoPizza, graficoBarrasQuantidadeDeChecklists, tabelaChecklistsAplicadas, tabelaNaoConformidades, infoViewer, mobileOpen, loading } = dadosContrato;

  // Atualizar o estado
  const setOpcoesContrato = (items) => setDadosContrato(prevState => ({ ...prevState, opcoesContrato: items }));
  const setSelectedContrato = (item) => setDadosContrato(prevState => ({ ...prevState, selectedContrato: item }));
  const setOpcoesStatus = (items) => setDadosContrato(prevState => ({ ...prevState, opcoesStatus: items }));
  const setSelectedStatus = (item) => setDadosContrato(prevState => ({ ...prevState, selectedStatus: item }));
  const setOpcoesTipo = (items) => setDadosContrato(prevState => ({ ...prevState, opcoesTipo: items }));
  const setSelectedTipo = (item) => setDadosContrato(prevState => ({ ...prevState, selectedTipo: item }));
  const setOpcoesSetores = (items) => setDadosContrato(prevState => ({ ...prevState, opcoesSetores: items }));
  const setSelectedSetores = (items) => setDadosContrato(prevState => ({ ...prevState, selectedSetores: items }));

  const setDescricao = (items) => setDadosContrato(prevState => ({ ...prevState, descricao: items }));
  const setCardsSuperiores = (items) => setDadosContrato(prevState => ({ ...prevState, cardsSuperiores: items }));
  const setNaoConformidadesEmAberto = (items) => setDadosContrato(prevState => ({ ...prevState, naoConformidadesEmAberto: items }));
  const setGraficoBarrasNaoConformidadesPorTipo = (items) => setDadosContrato(prevState => ({ ...prevState, graficoBarrasNaoConformidadesPorTipo: items }));
  const setGraficoPizza = (items) => setDadosContrato(prevState => ({ ...prevState, graficoPizza: items }));
  const setGraficoBarrasQuantidadeDeChecklists = (items) => setDadosContrato(prevState => ({ ...prevState, graficoBarrasQuantidadeDeChecklists: items }));
  const setTabelaChecklistsAplicadas = (items) => setDadosContrato(prevState => ({ ...prevState, tabelaChecklistsAplicadas: items }));
  const setTabelaNaoConformidades = (items) => setDadosContrato(prevState => ({ ...prevState, tabelaNaoConformidades: items }));
  const setInfoViewer = (items) => setDadosContrato(prevState => ({ ...prevState, infoViewer: items }));

  const setMobileOpen = (isOpen) => setDadosContrato(prevState => ({ ...prevState, mobileOpen: isOpen }));
  const setLoading = (isLoading) => setDadosContrato(prevState => ({ ...prevState, loading: isLoading }));

  //Fetch informações do backend
  const fetchControladores = useCallback((naoConformidades, listaDeNaoConformidades, naoConformidadesPorSetor) => {
    const extrairNaoConformidades = (source) =>
      Array.from(new Set(source?.map((item) => item.Checklist) || []));
  
    let tiposDeNaoConformidade;
  
    if (selectedSetores.length > 0) {
      const naoConformidadesPorSetorFormatadas = extrairNaoConformidades(
        [].concat(...naoConformidadesPorSetor.map((item) => item.naoConformidades))
      );
      tiposDeNaoConformidade = naoConformidadesPorSetorFormatadas.length > 0 ? naoConformidadesPorSetorFormatadas : [];
    } else {
      tiposDeNaoConformidade = extrairNaoConformidades(listaDeNaoConformidades);
    }
  
    setOpcoesTipo(tiposDeNaoConformidade);
    setOpcoesStatus(Array.from(new Set(naoConformidades.map((item) => item.Status))) ?? []);
  }, [selectedSetores]);

  const fetchDataInformacoes = useCallback(async (optionContrato, optionSetor, optionStatus, optionTipo, firstLoad = 0) => {
    try {
      // Setores recebe o primeiro setor para colorir o viewer quando nenhum estiver selecionado
      let setores = optionSetor;

      if(firstLoad === 1 || optionSetor.length === 0){
        if(opcoesSetores.length === 0){ // No caso de ter vindo do onChangeContrato
          setores = [optionSetor[0]?.Codigo_WBS];
        }else{ // No caso de ter vindo de ter vindo depois de deselecionar setores
          setores = [opcoesSetores[0]?.Codigo_WBS];
        }
        optionSetor = [];
      }

      const [descricaoResponse, cardsSuperioresResponse, graficoBarrasNaoConformidadesPorTipoResponse, tabelaChecklistsAplicadasResponse, naoConformidadesPorSetorResponse, listaDeNaoConformidadesResponse, graficoBarrasQuantidadeDeChecklistsResponse] = await Promise.all([
        //api.get(`cvi/dadosDescricaoQ?numContrato=${optionContrato}`),
        api.get(`cvi/dadosCardsGrausQ?numContrato=${optionContrato}&tipo=${optionTipo}&setores=${optionSetor}`),
        api.get(`cvi/dadosNaoConformidadePorTipoQ?numContrato=${optionContrato}&status=${optionStatus}&setores=${optionSetor}`),
        api.get(`cvi/dadosChecklistsAplicadasQ?numContrato=${optionContrato}&instancia=cvi&setores=${optionSetor}`),
        api.get(`cvi/dadosViewerQ?numContrato=${optionContrato}&setores=${setores}`),
        api.get(`cvi/dadosChecklistsNaoConformidadesQ?numContrato=${optionContrato}&instancia=cvi&setores=${optionSetor}`),
        api.get(`cvi/dadosQuantidadeDeChecklistsQ?numContrato=${optionContrato}&setores=${optionSetor}&tipo=${optionTipo}&status=${optionStatus}`)
      ]);
  
      //setDescricao(descricaoResponse.data[0]);
      setCardsSuperiores(cardsSuperioresResponse.data);
      setGraficoBarrasNaoConformidadesPorTipo(graficoBarrasNaoConformidadesPorTipoResponse.data);
      setTabelaChecklistsAplicadas(tabelaChecklistsAplicadasResponse.data);
      setGraficoBarrasQuantidadeDeChecklists(graficoBarrasQuantidadeDeChecklistsResponse.data)
  
      const naoConformidadesPorSetor = naoConformidadesPorSetorResponse.data;
      setInfoViewer(naoConformidadesPorSetor)

      const listaDeNaoConformidades = listaDeNaoConformidadesResponse.data.map((item) => ({ ...item, Plano_de_Acao: item.id, id: uuidv4() }));
      
      const naoConformidades = (naoConformidadesPorSetor.length > 0
        ? [].concat(...naoConformidadesPorSetor.map((item) => item.naoConformidades))
        : listaDeNaoConformidades).filter((item) => optionTipo === '' || item.Checklist === optionTipo);

      const filtrarNaoConformidades = (naoConformidades, tipo) =>
        naoConformidades.filter(
          item => item.Status !== 'Concluído' && item.Status !== 'Cancelado' && (tipo === '' || item.Checklist === tipo)
        );
  
      const quantidadeDeNaoConformidades = optionSetor.length > 0
        ? naoConformidadesPorSetor.reduce((acc, item) => acc + filtrarNaoConformidades(item.naoConformidades, optionTipo).length, 0)
        : filtrarNaoConformidades(listaDeNaoConformidades, optionTipo).length;

      fetchControladores(naoConformidades, listaDeNaoConformidades, naoConformidadesPorSetor)
  
      setTabelaNaoConformidades(naoConformidades.map((item) => ({ ...item, Plano_de_Acao: item.id, id: uuidv4() })));
      setGraficoPizza(groupBy(naoConformidades, 'Status'));
      setNaoConformidadesEmAberto(quantidadeDeNaoConformidades);
  
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [fetchControladores, opcoesSetores]);

  //HandleChange dos filtros
  const handleChangeSetor = useCallback((event) => {
    setLoading(true)
    setSelectedSetores(event.target.value)
    setMobileOpen(false);

    const optionSetores = event.target.value;

    fetchDataInformacoes(selectedContrato.split('/')[1], optionSetores, [], "");

  }, [selectedContrato, fetchDataInformacoes]); 

  const handleChangeTipo = useCallback((event) => {
    setLoading(true)
    setSelectedTipo(event.target.value);
    setMobileOpen(false);

    const optionTipo = event.target.value;

    fetchDataInformacoes(selectedContrato.split('/')[1], selectedSetores, selectedStatus, optionTipo);
    
  }, [selectedContrato, selectedSetores, selectedStatus, fetchDataInformacoes]); 

  const handleChangeStatus = useCallback((event) => {
    setLoading(true)
    setSelectedStatus(event.target.value);
    setMobileOpen(false);

    const optionStatus = event.target.value;

    fetchDataInformacoes(selectedContrato.split('/')[1], selectedSetores, optionStatus, selectedTipo);
    
  }, [selectedContrato, selectedSetores, selectedTipo, fetchDataInformacoes]); 

  const handleChangeContrato = useCallback((event) => {
    setLoading(true)
    setSelectedContrato(event.target.value);
    setMobileOpen(false);
  
    const optionContrato = event.target.value.split('/')[1].slice(0, -4);

    setSelectedSetores([])
    setSelectedStatus([])
    setSelectedTipo('')

    api
      .get(`cvi/opcoesSetoresQ?numContrato=${optionContrato}&instancia=cvi`)
      .then((setoresResponse) => {
        setOpcoesSetores(setoresResponse.data);
        fetchDataInformacoes(optionContrato, setoresResponse.data, [], "", 1);
    });

  }, [fetchDataInformacoes]); 

  //useEffect inicial, carrega os contratos, seleciona o primeiro contrato
  useEffect(() => {
    api
      .get('/token/listObjectsPBB?instancia=cvi')
      .then((response) => {
        const contratos = response.data.items;
        setOpcoesContrato(contratos);
        if (contratos.length > 0) {
          const primeiroContratoId = contratos[0].objectId;
          setSelectedContrato(primeiroContratoId);
          handleChangeContrato({ target: { value: primeiroContratoId } });
        }
      });
  },[]); 

  return (
    <div className="bg-cviPrimary">

      <CVIHeader menuOpen={mobileOpen} setMenuOpen={setMobileOpen}>
        {/* Filtro de Setor */}
        <Select
          className="min-w-[250px] max-w-[300px]"
          label="Setor"
          onChange={handleChangeSetor}
          value={selectedSetores}
          renderValue={(selected) => selected.join(', ')}
          multiple
        >
          {opcoesSetores.map(option => {
            const indent = ' '.repeat((option.Codigo_WBS.length - 7) / 3);
            const discriminacao = option.Discriminacao_WBS.length > 25 
              ? `${option.Discriminacao_WBS.slice(0, 25)}...` 
              : option.Discriminacao_WBS;
            
            return (
              <MenuItem
                key={option.Codigo_WBS}
                value={option.Codigo_WBS}
                style={{ display: 'flex', alignItems: 'center', padding: '4px 8px' }}
              >
                <Checkbox 
                  checked={selectedSetores.includes(option.Codigo_WBS)} 
                  style={{ padding: '0 8px' }} 
                />
                <Tooltip title={option.Discriminacao_WBS}>
                  <ListItemText
                    primary={<span style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{`${indent}${discriminacao}`}</span>}
                    style={{ marginLeft: '8px' }}
                  />
                </Tooltip>
              </MenuItem>
            );
          })}
        </Select>
        {/* Filtro de Tipo */}
        <Select
          className="min-w-[200px] max-w-[250px]"
          label="Tipo"
          onChange={handleChangeTipo}
          value={selectedTipo}
        >
          <MenuItem label="Todos" value="">
            Todos
          </MenuItem>
          {opcoesTipo.map((option, index) => (
      <MenuItem key={index} label={option} value={option}>
        {option}
      </MenuItem>
    ))}
        </Select>
        {/* Filtro de Status */}
        <Select
          className="min-w-[150px] max-w-[200px]"
          label="Status"
          onChange={handleChangeStatus}
          value={selectedStatus}
          multiple
          renderValue={(selected) => selected.join(', ')}
        >
          {opcoesStatus.map((option) => (  
      <MenuItem key={option} label={option} value={option}>
        <Checkbox checked={selectedStatus.indexOf(option) > -1} style={{ padding: '0 8px' }} />
        {option}
      </MenuItem>
    ))}
        </Select>
        {/* Filtro de Contrato */}
        <Select
          className="min-w-[200px] max-w-[250px]"
          label="Contrato"
          onChange={handleChangeContrato}
          value={selectedContrato}
        >
          {opcoesContrato?.map((opcao) => (
            <MenuItem
              key={opcao?.objectId}
              label={opcao?.objectKey.slice(0, 8)}
              value={opcao?.objectId}>
              {opcao?.objectKey.slice(0, opcao.objectKey.length - 4)}
            </MenuItem>
          ))}
        </Select>
      </CVIHeader>

      <div className="mt-16 p-4 space-y-4"> 

        {/* Linha 1 - Cards superiores */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4">

            <IconCard
              value={descricao?.Objeto_Reduzido ?? 'Indefinido'}
              label="Descrição"
              icon={iconQualidade}
              className="bg-cviSecondary"
              isLoading={loading}
            />

            <Card
              title="Checklists Preenchidas"
              value={
                selectedStatus.length === 0?(selectedTipo === ''
                  ? tabelaChecklistsAplicadas?.length ?? 0
                  : tabelaChecklistsAplicadas?.filter(item => item.Checklist === selectedTipo).length ?? 0):"-"
              }
              isLoading={loading}
              className="bg-cviSecondary"
            />

            <Card
              title="Grau de Conformidades"
              value={selectedStatus.length === 0?`${((cardsSuperiores?.grauConformidade?.GRAU || 0) * 100).toFixed(2)}%`:"-"}
              isLoading={loading}
              className="bg-cviSecondary"
            />


            <Tooltip title={
              <div style={{ fontSize: '14px', padding: '8px' }}>
                {`Não conformidades em aberto: ${selectedStatus.length === 0 ? naoConformidadesEmAberto : "-"}`}
              </div>}>
              <div className="bg-cviSecondary text-white rounded flex flex-col gap-2 items-center justify-center text-center p-3">
                <Card
                    title="Grau de Não Conformidades"
                    value={selectedStatus.length === 0?`${((cardsSuperiores?.grauNaoConformidade?.GRAU || 0) * 100).toFixed(2)}%`:"-"}
                    isLoading={loading}
                    className="bg-cviSecondary"
                  />
              </div>
            </Tooltip>

            <Card
              title="Grau de Itens que Não se Aplicam"
              value={selectedStatus.length === 0?`${((cardsSuperiores?.grauNaoSeAplica?.GRAU || 0) * 100).toFixed(2)}%`:"-"}
              isLoading={loading}
              className="bg-cviSecondary"
            />

        </div>

        {/* Linha 2 - Viewer e gráficos */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">

          {/* Viewer */}
          <div className="flex flex-col gap-6 h-[480px] lg:h-full bg-cviSecondary text-body2 p-2 text-center text-secondary rounded">
              <Viewer
                tipo="qualidade"
                selectedContract={selectedContrato}
                selectedSetores={
                  selectedStatus.length === 0? infoViewer
                  : infoViewer.map(setorObj => ({
                    ...setorObj,
                    naoConformidades: setorObj.naoConformidades.filter(nc => selectedStatus.includes(nc.Status)),
                    possuiNaoConformidades: setorObj.naoConformidades.some(nc => selectedStatus.includes(nc.Status))
                  }))
                }
              />
              {/* Legenda do Viewer */}
              <div className="flex justify-center items-center gap-4 pb-1">
                <div className="flex items-center gap-2">
                  <div className="w-4 h-4 bg-cardVermelho"></div>
                  <span className="text-sm text-cardVermelho">Não conformes</span>
                </div>
                <div className="flex items-center gap-2">
                  <div className="w-4 h-4 bg-cardVerde"></div>
                  <span className="text-sm text-cardVerde">Conformes</span>
                </div>
              </div>
          </div>

          {/* Gráficos */}
          <div className="flex flex-col gap-4 order-[-1]">

            {/* Linha 1 */}
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">

              {/* Barras Não COnformidades por Tipo*/}
              <BarChartSimple
                label="Não Conformidades por Tipo"
                data={
                  selectedTipo === ''
                    ? graficoBarrasNaoConformidadesPorTipo
                    : graficoBarrasNaoConformidadesPorTipo?.filter(item => item.CHECKLIST === selectedTipo)
                }
                type="NCPT"
                isLoading={loading}
                color="cvi"
              />
              {/* Pizza Quantidade de Não Conformidades por Status */}
              <PizzaChart 
                  data={selectedStatus.length !== 0? 
                    graficoPizza?.filter((item) => selectedStatus.includes(item.Status))
                    : graficoPizza} 
                  isLoading={loading}
                  className="bg-cviSecondary" />

            </div>

            {/* Linha 2 */}
            <div className="text-white">

              {/* Barras Quantidade de Checklists e NCs/Mês */}
              <BarChartTrend
                data={graficoBarrasQuantidadeDeChecklists}
                isLoading={loading}
                className="bg-cviSecondary"
              />

            </div>
          </div>
        </div>

        {/* Linha 3 - Tabelas */}
        <div className={'grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4'}>
        <Tabs defaultValue="checklists">

          <TabsList className="bg-cviSecondary"> 
            <TabsTrigger className="data-[state=active]:bg-cviPrimary" value="checklists">Checklists Aplicadas</TabsTrigger>
            <TabsTrigger className="data-[state=active]:bg-cviPrimary" value="unconformities">Não Conformidades</TabsTrigger>
          </TabsList>

          <div className="items-center justify-center text-white col-span-1 rounded">

            <TabsContent value="checklists" className="bg-cviSecondary p-2 text-center rounded">
              {loading ? (
                <div className="h-48 w-full grid place-items-center">
                  <Spinner className="m-auto"/>
                </div>
              ) : (
                <TabelaChecklists
                  data={
                    selectedTipo !== ""
                      ? ((tabelaChecklistsAplicadas?.map((item) => ({ ...item, id: uuidv4() })) ?? []).filter((item) => item.Checklist === selectedTipo))
                      : tabelaChecklistsAplicadas?.map((item) => ({ ...item, id: uuidv4() })) ?? []
                    }
                />
              )}  
            </TabsContent>

            <TabsContent value="unconformities" className="bg-cviSecondary p-2 text-center rounded">
              {loading ? (
                <div className="h-48 w-full grid place-items-center">
                  <Spinner className="m-auto"/>
                </div>
              ) : (
                <TabelaPlanoDeAcao
                  data={
                    selectedStatus.length !== 0
                      ? tabelaNaoConformidades?.filter((item) => selectedStatus.includes(item.Status))
                      : tabelaNaoConformidades
                  }
                />
              )} 
              
            </TabsContent>

          </div>

        </Tabs>

        </div>

      </div>
    </div>
  );
}
export { QualidadeCVI };
