import { useState } from 'react';
import { Spinner } from '../Spinner';
import { cn } from '../../lib/utils';
import InfoIcon from '../../assets/icons/icon-info.svg';

export function SimpleCard({ label, content, isLoading, tooltip, className }) { 
  
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div 
      className={cn(
        'p-2 flex flex-col gap-2 rounded', 
        isLoading && 'items-center justify-center h-[72px]',
        className
      )}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        transition: 'background-color 0.3s ease-in-out', 
      }}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <div className="flex items-center gap-2">
            <span>{label}</span>
            <img 
              title={tooltip} src={InfoIcon} alt={"info"}
              style={{
                width: '15px', height: '15px', verticalAlign: 'baseline',
                opacity: isHovered ? 1 : 0, transition: 'opacity 0.3s ease-in-out', 
              }} 
            />
          </div>
          <strong>{content}</strong>
        </>
      )}
    </div>
  );
}
