import React, { useMemo } from 'react';
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  CartesianGrid
} from 'recharts';
import { cn } from '../../lib/utils';
import { Spinner } from '../Spinner';

const meses = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];

export function BarChartTrend({ data, isLoading, className }) {

  const chartData = useMemo(() => {
    if (!data) {
      return [];
    }

    const sortedData = data.sort((a, b) => {
      // Convertendo as datas para objetos Date para comparar
      const dateA = new Date(`${a.MESES.split('-')[1]}/${a.MESES.split('-')[0]}`);
      const dateB = new Date(`${b.MESES.split('-')[1]}/${b.MESES.split('-')[0]}`);
        
      // Ordenando em ordem crescente
      return dateA - dateB;
    });
  
  
    // Agrupando os dados por mês e ano
    const groupedData = {};
    sortedData.forEach(item => {
      const [day, month, year] = item.MESES.split('-'); // Dividindo a data em dia, mês e ano
      const monthYear = `${month.padStart(2, '0')}-${year}`; // Garantindo que o mês tenha dois dígitos
      if (!groupedData[monthYear]) {
        groupedData[monthYear] = {
          name: `${meses[parseInt(month, 10) - 1]}/${year}`, // Usando o array de meses para obter o nome do mês
          Checklists: 0,
          'Não Conformes': 0,
          Conformes: 0
        };
      }
      groupedData[monthYear].Checklists += item.QTDS_CHECKLISTS;
      groupedData[monthYear]['Não Conformes'] += item.QTDS_PLANOACAO;
      groupedData[monthYear].Conformes += item.QTDS_CONFORMES;
    });
  
    // Transformando os dados agrupados em um array
    const chartData = Object.values(groupedData);
  
    // Ordenando os dados por mês/ano
    chartData.sort((a, b) => {
      const [monthA, yearA] = a.name.split('/');
      const [monthB, yearB] = b.name.split('/');
      return new Date(yearA, monthA - 1) - new Date(yearB, monthB - 1);
    });
  
    // Calculando checklists acumuladas
    let accumulatedChecklists = 0;
    chartData.forEach(item => {
      item['Checklists Acumuladas'] = accumulatedChecklists + item.Checklists;
      accumulatedChecklists = item['Checklists Acumuladas'];
    });
  
    return chartData;
  }, [data]);
  

  return (
    <div className={cn('rounded w-full p-2 flex flex-col gap-2 min-h-[300px]', className)}>
      <h3 className="text-base font-medium text-center text-white">
        Quantidade de Checklists e NCs/Mês
      </h3>

      {isLoading ? (
        <div className="w-full h-[300px] flex justify-center items-center">
          <Spinner className="text-white w-20 h-20 flex" />
        </div>
      ) : (
        <ResponsiveContainer width="100%" height={300}>
          <ComposedChart data={chartData}>
            <CartesianGrid horizontal={false} strokeDasharray="1 1" />

            <XAxis stroke="white" dataKey="name" />
            <YAxis 
                stroke="white" 
                dataKey="Conformes" 
                orientation="left" 
                yAxisId="left" 
                interval={0} 
                tickFormatter={(value) => Number.isInteger(value) ? value : ''}
            />
            <YAxis 
                stroke="#0088FE" 
                dataKey="Checklists Acumuladas" 
                orientation="right" 
                yAxisId="right" 
                interval={0} 
                tickFormatter={(value) => Number.isInteger(value) ? value : ''}
            />

            <Legend />

            <Bar barSize={12} yAxisId="left" dataKey="Conformes" fill="#62C17E" />
            <Bar barSize={12} yAxisId="left" dataKey="Não Conformes" fill="#ff0000" />
            <Tooltip cursor={{ fill: 'transparent' }} />

            <Line
              type="monotone"
              dataKey="Checklists Acumuladas"
              strokeWidth={4}
              stroke="#0088FE"
              yAxisId="right"
            />
          </ComposedChart>
        </ResponsiveContainer>
      )}
    </div>
  );
}
