import React, { useEffect, useState } from 'react';

import { v4 as uuidv4 } from 'uuid';

import { api } from '../../../lib/axios';

import { Header } from '../../../Components/Header.js';
import iconMoeda from '../../../assets/icons/moeda.png';

import TabelaBms from '../../../Components/Tabelas/TabelaBMs';
import TabelaServicosSetor from '../../../Components/Tabelas/ServicosBMs';
import BarChart from '../../../Components/Charts/BarChart';
import Viewer from '../../../Components/Viewer/Viewer';
import { AvancoFinanceiroCard } from '../../../Components/Cards/AvancoFinanceiroCard';
import { IconCard } from '../../../Components/Cards/IconCard';
import { Select } from '../../../Components/Select';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../../Components/Tabs';

import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';

//----------

function FinanceiroPlugBase() {

  //Controlador de contrato
  const [opcoesContrato, setOpcoesContrato] = useState([]);
  const [selectedContrato, setSelectedContrato] = useState(''); 
  //Controlador de Setores
  const [opcoesSetor, setOpcoesSetor] = useState([]);
  const [selectedSetor, setSelectedSetor] = useState([]);
  //Controlador de BMs
  const [opcoesBMs, setOpcoesBMs] = useState([]);
  const [selectedBM, setSelectedBM] = useState('');

  //Setores para o 3D Viewer
  const [setorComProgresso, setSetorComProgresso] = useState([]);


  //Card Vigência
  const [opcoesDataVigencia, setOpcoesDataVigencia] = useState([]);
  //Cards
  const [valoresPrevistoXExecutado, setValoresPrevistoXExecutado] = useState([]);
  //Card Valor Previsto Acumulado
  const [valorPrevistoAcumulado, setValorPrevistoAcumulado] = useState([]);
  //Gráfico Previsto x Executado
  const [opcoesPrevistoXExecutado, setOpcoesPrevistoXExecutado] = useState([]);
  //Tabela de BMs
  const [opcoesDataTableBms, setOpcoesDataTableBms] = useState([]);
  //Tabela de Serviços
  const [opcoesDataTableServicos, setOpcoesDataTableServicos] = useState([]);
  // Controle da versão mobile
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleChangeContrato = (event) => {
    setSelectedContrato(event.target.value);

    const optionContrato = event.target.value.split('/')[1];

    fetchDataControladores(optionContrato);
    fetchDataInformacoes(optionContrato, selectedBM, selectedSetor);
  };

  const handleChangeBM = (event) => {
    setSelectedBM(event.target.value);
    setMobileOpen(false);

    const optionBM = event.target.value;

    fetchDataInformacoes(selectedContrato.split('/')[1], optionBM, selectedSetor);
    fetchDataViewer(selectedContrato.split('/')[1], optionBM, selectedSetor);
    
  };

  const handleChangeSetor = (event) => {
    setSelectedSetor(event.target.value)
    setMobileOpen(false);

    const optionSetor = event.target.value;

    fetchDataInformacoes(selectedContrato.split('/')[1], selectedBM, optionSetor);
    fetchDataViewer(selectedContrato.split('/')[1], selectedBM, optionSetor)
  };

  const fetchDataControladores = (optionContrato) => {
    Promise.all([
      api.get(`/dadossetor?numContrato=${optionContrato}&instancia=plugbase`),
      api.get(`/dadosbm?numContrato=${optionContrato}&instancia=plugbase`),
      api.get(`/dadosvigencia?numContrato=${optionContrato}&instancia=plugbase`),
      api.get(`/dadoschartdata?numContrato=${optionContrato}&instancia=plugbase`)
    ]).then(([setorResponse, bmResponse, vigenciaResponse, chartDataResponse]) => {
      setOpcoesSetor(setorResponse.data);
      setOpcoesBMs(bmResponse.data);
      setOpcoesDataVigencia(vigenciaResponse.data);
      setOpcoesPrevistoXExecutado(chartDataResponse.data);

      fetchDataViewer(optionContrato, selectedBM, setorResponse.data);
    });
  };

  const fetchDataInformacoes = (optionContrato, optionBM, optionSetor) => {
    Promise.all([
      api.get(`/dadostabelabms?numContrato=${optionContrato}&setor=&bm=${optionBM}&instancia=plugbase`),
      api.get(`/valorprevistoacumulado?numContrato=${optionContrato}&setor=&bm=${optionBM}&instancia=plugbase`),
      api.get(`/dadostabelaservices?numContrato=${optionContrato}&setor=${optionSetor}&instancia=plugbase`)
    ]).then(([tabelaBMResponse, valorPrevistoAcumuladoResponse, tabelaServicosResponse]) => {
      setOpcoesDataTableBms(tabelaBMResponse.data)
      setValoresPrevistoXExecutado(tabelaBMResponse.data.length > 0 ? [tabelaBMResponse.data[tabelaBMResponse.data.length - 1]] : tabelaBMResponse.data);
      setValorPrevistoAcumulado(valorPrevistoAcumuladoResponse.data);
      setOpcoesDataTableServicos(tabelaServicosResponse.data);
    });
  };

  const fetchDataViewer = (optionContrato, optionBM, optionSetor) => {
    setSetorComProgresso([]);
    //Setores selecionados
    let setoresSelecionados = optionSetor; 

    if (optionSetor && optionSetor.length > 0) {
      if (optionSetor[0].Codigo_WBS){
        setoresSelecionados = optionSetor.map(objeto => objeto.Codigo_WBS);
      }
    }else{
      setoresSelecionados = opcoesSetor.map(objeto => objeto.Codigo_WBS);
    }
    //Cria uma array com as chamadas a serem feitas
    const promises = setoresSelecionados.map(codSetor =>
      api.get(`/dadostabelabms2?numContrato=${optionContrato}&setor=${codSetor}&bm=${optionBM}&instancia=plugbase`)
    );
    Promise.all(promises)
      .then(responses => {
        const newSetores = responses.reduce((acc, response) => {
          if (response.data && response.data.length > 0) {
            const existsInArray = acc.findIndex(item => item.Codigo_WBS === response.data[0].Codigo_WBS);
            if (existsInArray === -1) {
              acc.push(response.data[0]);
            }
          }
          return acc;
        }, []);
        setSetorComProgresso(prevState => [...prevState, ...newSetores]);
      });
  };

  //useEffect inicial, carrega os contratos, seleciona o primeiro contrato
  useEffect(() => {
    api
      .get('/token/listObjectsPBB?instancia=plugbase')
      .then((response) => {
        setOpcoesContrato(response.data.items);
        setSelectedContrato(response.data.items[0].objectId);
        handleChangeContrato({ target: { value: response.data.items[0].objectId } });
      });
  }, []);


  return (
    <div className="bg-plugPrimary">

      <Header menuOpen={mobileOpen} setMenuOpen={setMobileOpen}>  
        <Select
          className="min-w-[200px] max-w-[250px]"
          label="Setor"
          onChange={handleChangeSetor}
          value={selectedSetor}
          renderValue={(selected) => selected.join(', ')}
          multiple
        >
          {opcoesSetor.map(option => {
            const indent = ' '.repeat((option.Codigo_WBS.length - 7) / 3); // Using Unicode character for indent
            const discriminacao = option.Discriminacao_WBS.length > 25 
              ? `${option.Discriminacao_WBS.slice(0, 25)}...` 
              : option.Discriminacao_WBS;
            
            return (
              <MenuItem
                key={option.Codigo_WBS}
                value={option.Codigo_WBS}
                style={{ display: 'flex', alignItems: 'center', padding: '4px 8px' }}
              >
                <Checkbox 
                  checked={selectedSetor.includes(option.Codigo_WBS)} 
                  style={{ padding: '0 8px' }} 
                />
                <Tooltip title={option.Discriminacao_WBS}>
                  <ListItemText
                    primary={<span style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{`${indent}${discriminacao}`}</span>}
                    style={{ marginLeft: '8px' }}
                  />
                </Tooltip>
              </MenuItem>
            );
          })}
        </Select>

        <Select
          className="min-w-[200px] max-w-[250px]"
          label="BM"
          onChange={handleChangeBM}
          value={selectedBM}
        > 
          <MenuItem key={uuidv4()} label={'Todos'} value={''}>
            {'Todos'}
          </MenuItem>
          {(opcoesBMs.map((opcao) => (
            <MenuItem key={opcao.BMSP} label={opcao.BMSP} value={opcao.BMSP}>
              {opcao.BMSP}
            </MenuItem>
          )))}
        </Select>

        <Select
          className="min-w-[200px] max-w-[250px]"
          label="Contrato"
          onChange={handleChangeContrato}
          value={selectedContrato}
        > 
          {opcoesContrato.map((opcao) => (
            <MenuItem
              key={opcao.objectId}
              label={opcao.objectKey.slice(0, 8)}
              value={opcao.objectId}>
              {opcao.objectKey.slice(0, opcao.objectKey.length - 4)}
            </MenuItem>
          ))}
        </Select>
      </Header>

      <div className="mt-16 p-4 space-y-4">
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4">
          <div className="col-span-1">
              <IconCard
                value={opcoesDataVigencia.map(
                  (opcao) =>
                    `${new Date(opcao.Data_de_Inicio)
                      .toLocaleDateString('pt-BR')
                      .toString()} A ${new Date(opcao.Data_de_Termino_Atual_Vigencia)
                      .toLocaleDateString('pt-BR')
                      .toString()}`
                )}
                label="Prazo de Vigência"
                icon={iconMoeda}
                className={'bg-plugSecondary'}
              />
          </div>
          <div className="col-span-1">
              <IconCard
                value={
                  `R$ ` +
                  valoresPrevistoXExecutado.map((opcao) =>
                    new Intl.NumberFormat('pt-BR').format(opcao.ValorPrevisto_WBS)
                  )
                }
                label="Valor Atual do Contrato"
                className={'bg-plugSecondary'}
                icon={iconMoeda}
              />
          </div>
          <div className="col-span-1">
              <IconCard
                value={
                  `R$ ` +
                  valorPrevistoAcumulado.map((opcao) =>
                    new Intl.NumberFormat('pt-BR').format(opcao.Total_Previsto_Ate_Atual)
                  )
                }
                label="Valor Previsto Acumulado"
                className={'bg-plugSecondary'}
                icon={iconMoeda}
              />
          </div>

          <div className="col-span-1">
              <IconCard
                value={
                  `R$ ` +
                  valoresPrevistoXExecutado.map((opcao) =>
                    new Intl.NumberFormat('pt-BR').format(opcao.ValorAcumulado_WBS)
                  )
                }
                label="Valor Medido Acumulado"
                className={'bg-plugSecondary'}
                colorIcon="#62C17E"
                icon={iconMoeda}
              />
          </div>

          <div className="col-span-1">
              <AvancoFinanceiroCard
                value={valoresPrevistoXExecutado}
                label={'Avanço Financeiro'}
                className={'bg-plugSecondary'}
              />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-7 gap-4">
          <div className="col-span-4 flex flex-col gap-6 h-[480px] lg:h-full bg-plugSecondary p-2 text-center rounded">
              <Viewer selectedContract={selectedContrato} selectedSetores={setorComProgresso} />
              <div className="flex justify-center items-center gap-4 pb-1">
                <div className="flex items-center gap-2">
                  <div className="w-4 h-4 bg-cardVerde"></div>
                    <span className="text-sm text-cardVerde">Concluído</span>
                </div>
                <div className="flex items-center gap-2">
                  <div className="w-4 h-4 bg-cardAmarelo"></div>
                  <span className="text-sm text-cardAmarelo">Em andamento</span>
                </div>
                <div className="flex items-center gap-2">
                  <div className="w-4 h-4 bg-cardVermelho"></div>
                  <span className="text-sm text-cardVermelho">A iniciar</span>
                </div>
              </div>
          </div>
          <div className="col-span-3 bg-plugSecondary p-2 text-center rounded" style={{ height: '65vh' }}>
              <BarChart 
                opcoesPrevistoXExecutado={opcoesPrevistoXExecutado}
                altura={'60vh'}
                cabecalho={'Previsto x Realizado - Execução Financeira'}
                modulo={'F'}
                eixo={'x'}
              />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4">
          <div className="col-span-1">
            <Tabs defaultValue="bms">
              <TabsList className="bg-plugSecondary">
                <TabsTrigger className="data-[state=active]:bg-plugPrimary" value="bms">BMs</TabsTrigger>
                <TabsTrigger className="data-[state=active]:bg-plugPrimary" value="servicos">Serviços</TabsTrigger>
              </TabsList>
              <TabsContent value="bms" className="bg-plugSecondary p-2 text-center rounded">
                  <TabelaBms altura={'300px'} opcoesDataTableBms={opcoesDataTableBms} />
              </TabsContent>
              <TabsContent value="servicos" className="bg-plugSecondary p-2 text-center rounded">
                  <TabelaServicosSetor
                    altura={'300px'}
                    opcoesDataTableServicos={
                      selectedBM? 
                        opcoesDataTableServicos?.filter((item) =>(item?.BMSP === selectedBM))
                        :
                        opcoesDataTableServicos}
                  />
              </TabsContent>
            </Tabs>
          </div>
        </div>

      </div>
    </div>
  );
}

export { FinanceiroPlugBase };
