import Client from '../../Autenticate/Auth';
//import * as THREE from "three";
//import {ObjectsApi, Autodesk } from 'forge-apis'; 

const Autodesk = window.Autodesk;
const THREE = window.THREE;
// const green = new THREE.Color(255, 255, 0);

var getToken = { accessToken: Client.getAccesstoken() };

//var viewer;
var viewer = window.NOP_VIEWER;

function utf8_to_b64(str) {
  return window.btoa(unescape(encodeURIComponent(str)));
}

function filterModelBySetorBM(setor) {
  console.log(setor)

  if (setor.length === 0) {
    try {
      viewer?.clearThemingColors();
      viewer?.clearSelection();
      viewer?.isolate([]);
    } catch (error) {
      console.log(error);
    }
  }

  setor.forEach((codigo_valor) => {
    try {
      viewer.search(
        codigo_valor.Codigo_WBS,
        (e) => {

          viewer.select(e, Autodesk.Viewing.SelectionMode.REGULAR);
          viewer.isolate(e); // Armazena o elemento encontrado no array
          //viewer.clearThemingColors();
          //viewer.setSelectionColor(new THREE.Vector4(255, 0, 0));

          if (codigo_valor.Divisao_Acumulado_Previsto === 0) { //vermelho
            e.forEach((dbId) =>{
              viewer.setThemingColor(dbId, new THREE.Vector4(255, 0, 0), null, true)}
            );
          } else if (
            codigo_valor.Divisao_Acumulado_Previsto > 0 &&
            codigo_valor.Divisao_Acumulado_Previsto < 100
          ) { // amarelo
            e.forEach((dbId) =>
              {viewer.setThemingColor(dbId, new THREE.Vector4(255, 255 ,0), null, true)}
            );
          } else { //verde
            e.forEach((dbId) =>
              {viewer.setThemingColor(dbId, new THREE.Vector4(0, 255, 0), null, true)}
            );
          }
        },
        (error) => {
          console.log({ error });
        }
      );
    } catch (error) {}
  });
}

export function filterModelBySetor(setor) {

  if (setor.length === 0) {
    try {
      viewer?.clearThemingColors();
      viewer?.clearSelection();
      viewer?.isolate([]);
    } catch (error) {
      console.log(error);
    }
  }

  try {
    viewer.clearThemingColors();
  } catch (error) {}

  // vermelho 255, 0, 0
  // verde 0, 255, 0

  setor.forEach((item) => {
    try {
      viewer.search(
        item.setor,
        (e) => {
          viewer.select(e, Autodesk.Viewing.SelectionMode.REGULAR);
          viewer.isolate(e);
          if (item.possuiNaoConformidades) {
            e.forEach((dbId) => {
              viewer.setThemingColor(dbId, new THREE.Vector4(0, 255, 0), null, true)
              item.naoConformidades.forEach((item) => {
                try {
                  viewer.search(
                    item.elemento,
                    (f) => {
                      f.forEach((dbId) => {
                        viewer.setThemingColor(dbId, new THREE.Vector4(255, 0, 0), null, true)
                      });
                    },
                    (error) => {
                      console.log({ error });
                    }
                  );
                } catch (error) {}
              });
            });
          } else {
            e.forEach(
              (dbId) =>
                viewer.setThemingColor(dbId, new THREE.Vector4(0, 255, 0), null, true)
            );
          }
        },
        (error) => {
          console.log({ error });
        }
      );
    } catch (error) {}
  });
}

export async function filterModelBySetorBraskem(setor) {
  if (setor.length === 0) {
    try {
      viewer?.clearThemingColors(null);
      viewer?.clearSelection();
      viewer?.isolate([]);
    } catch (error) {
      console.log(error);
    }
    return;
  }

  try {
    viewer?.clearThemingColors(null);
    viewer?.clearSelection();
    viewer?.isolate([]);
  } catch (error) {
    console.log(error);
  }

  // vermelho 255, 0, 0
  // verde 0, 255, 0

  async function searchAndColor(viewer, item) {
    return new Promise((resolve, reject) => {
      viewer.search(
        item.setor,
        (elements) => {
          if (elements.length === 0) return resolve();
          //viewer.select(elements, Autodesk.Viewing.SelectionMode.REGULAR);
          //viewer.isolate(elements);

          if (item.setor !== 'TPF_EDF') {
            viewer.isolate(elements);
          }

          if (item.possuiNaoConformidades) {
            // elements.forEach((dbId) => {
            //   viewer.setThemingColor(dbId, new THREE.Vector4(0, 255, 0), null, true);
            // });
            const naoConformidadesPromises = item.naoConformidades.map((naoConformidade) =>
              searchAndColorElement(viewer, naoConformidade)
            );
            

            Promise.all(naoConformidadesPromises).then(resolve).catch(reject);
          } else {
            elements.forEach((dbId) => {
              viewer.setThemingColor(dbId, new THREE.Vector4(0, 255, 0), null, true);
            });
            resolve();
          }
        },
        (error) => {
          console.log({ error });
          reject(error);
        }
      );
    });
  }

  async function searchAndColorElement(viewer, naoConformidade) {
    return new Promise((resolve, reject) => {
      viewer.search(
        naoConformidade.elemento,
        (elements) => {
          elements.forEach((dbId) => {
            if (naoConformidade.Status === 'Concluído' || naoConformidade.Status === 'Cancelado') {
              viewer.setThemingColor(dbId, new THREE.Vector4(0, 255, 0), null, true);
            } else if (naoConformidade.Status !== 'x') {
              viewer.setThemingColor(dbId, new THREE.Vector4(255, 0, 0), null, true);
            }
          });
          resolve();
        },
        (error) => {
          console.log({ error });
          reject(error);
        }
      );
    });
  }


  try {
    for (const item of setor) {
      await searchAndColor(viewer, item);
    }
  } catch (error) {
    console.error(error);
  }
}


export function filterModelByTask(tasks, selectedTasks, selectedType, extraData) {

  if (tasks.length === 0) {
    try {
      viewer?.clearThemingColors();
    } catch (error) {
      console.log(error);
    }
  }

  if (selectedTasks === null) {
    try {
      viewer?.isolate([]);
    } catch (error) {
      console.log(error);
    }
  }

  try {
    viewer?.clearThemingColors();
  } catch (error) {}

  tasks.forEach((item) => {
    try {
      if (item.COD_WBSTexto4) {
        viewer.search(
          item.COD_WBSTexto4,
          (e) => {
            if (selectedType === 'avanco') {
              if (item.Realizado === 0) {
                e?.forEach((dbId) => {
                  viewer.setThemingColor(dbId, new THREE.Vector4(255, 0, 0), null, true);
                });
              } else if (item.Realizado > 0 && item.Realizado < 1) {
                e?.forEach((dbId) => {
                  viewer.setThemingColor(dbId, new THREE.Vector4(255, 255, 0), null, true);
                });
              } else {
                e?.forEach((dbId) => {
                  viewer.setThemingColor(dbId, new THREE.Vector4(0, 255, 0), null, true);
                });
              }
              
            } else {
              const taskWithDates = extraData?.find(
                (extraDataItem) => extraDataItem.TaskId === item.TaskId
              );

              if (item.Realizado === 1) {
                e?.forEach((dbId) => {
                  viewer.setThemingColor(dbId, new THREE.Vector4(0, 255, 0), null, true);
                });
              } else if (
                item.Realizado < 1 &&
                Date.parse(taskWithDates?.MaiorBaseline) <
                  Date.parse(taskWithDates?.MaiorTimephased)
              ) {
                e.forEach((dbId) => {
                  viewer.setThemingColor(dbId, new THREE.Vector4(255, 0, 0), null, true);
                });
              } else if (
                item.Realizado < 1 &&
                Date.parse(taskWithDates?.MaiorBaseline) >=
                  Date.parse(taskWithDates?.MaiorTimephased)
              ) {
                e?.forEach((dbId) => {
                  viewer.setThemingColor(dbId, new THREE.Vector4(255, 255, 0), null, true);
                });
              }
            }
          },
          (error) => {
            console.log({ error: 'oi' });
          }
        );
      }
    } catch (error) {
      console.log('joao');
    }
  });

  if (selectedTasks && selectedTasks.length > 0) {
    selectedTasks.forEach((item) => {
      try {
        if (item.COD_WBSTexto4) {
          viewer.search(
            item.COD_WBSTexto4,
            (e) => {

              if (e.length > 0) {
                viewer.isolate(e);
                viewer.fitToView(e);
              }
            },
            (error) => {}
          );
        }
      } catch (error) {
        console.log('[SELECTED_TASKS_VIEWER_ERROR]', error);
      }
    });
  }
}

export async function filterModelByTaskBraskem(tasks, selectedTasks, selectedType, extraData) {
  if (tasks.length === 0) {
    try {
      viewer?.clearThemingColors();
    } catch (error) {
      console.log(error);
    }
    return;
  }

  try {
    viewer?.clearThemingColors();
  } catch (error) {
    console.log(error);
  }

  const searchAndColorTaskAvanco = async (item) => {
    return new Promise((resolve, reject) => {
      if (item.COD_WBSTexto4) {
        viewer.search(
          item.COD_WBSTexto4,
          (elements) => {
              elements.forEach((dbId) => {
                if (item.Realizado === 0) {
                  viewer.setThemingColor(dbId, new THREE.Vector4(255, 0, 0), null, true);
                } else if (item.Realizado > 0 && item.Realizado < 1) {
                  viewer.setThemingColor(dbId, new THREE.Vector4(255, 255, 0), null, true);
                } else {
                  viewer.setThemingColor(dbId, new THREE.Vector4(0, 255, 0), null, true);
                }
              });
            resolve();
          },
          (error) => {
            console.log({ error });
            reject(error);
          }
        );
      } else {
        resolve();
      }
    });
  };

  const searchAndColorTaskCronograma = async (item) => {
    return new Promise((resolve, reject) => {
      if (item.COD_WBSTexto4) {
        viewer.search(
          item.COD_WBSTexto4,
          (elements) => {
              const taskWithDates = extraData?.find(
                (extraDataItem) => extraDataItem.TaskId === item.TaskId
              );
              if (item.Realizado === 1) {
                elements?.forEach((dbId) => {
                  viewer.setThemingColor(dbId, new THREE.Vector4(0, 255, 0), null, true);
                });
              } else if (
                item.Realizado < 1 &&
                Date.parse(taskWithDates?.MaiorBaseline) <
                Date.parse(taskWithDates?.MaiorTimephased)
              ) {
                elements.forEach((dbId) => {
                  viewer.setThemingColor(dbId, new THREE.Vector4(255, 0, 0), null, true);
                });
              } else if (
                item.Realizado < 1 &&
                Date.parse(taskWithDates?.MaiorBaseline) >=
                Date.parse(taskWithDates?.MaiorTimephased)
              ) {
                elements?.forEach((dbId) => {
                  viewer.setThemingColor(dbId, new THREE.Vector4(255, 255, 0), null, true);
                });
              }
            resolve();
          },
          (error) => {
            console.log({ error });
            reject(error);
          }
        );
      } else {
        resolve();
      }
    });
  };

  try {
    if(selectedType === 'avanco'){
      for (const item of tasks) {
        await searchAndColorTaskAvanco(item);
      }
    }else if(selectedType === 'cronograma'){
      for (const item of tasks) {
        await searchAndColorTaskCronograma(item);
      }
    } 
  } catch (error) {
    console.error("Erro na iteração");
  }

}


function launchViewer(div, urn) {

  const objectId = utf8_to_b64(urn);

  getToken.accessToken.then((token) => {
    var options = {
      env: 'AutodeskProduction',
      accessToken: token.access_token
    };

    Autodesk.Viewing.Initializer(options, function () {
      document.getElementById(div).innerHTML = '';
      var htmlDiv = document.getElementById(div);
      viewer = new Autodesk.Viewing.GuiViewer3D(htmlDiv);
      var startedCode = viewer.start();
      if (startedCode > 0) {
        console.error('Failed to create a Viewer: WebGL not supported.');
        return;
      }

      console.log('Initialization complete, loading a model next...');
    });

    var documentId = 'urn:' + objectId;
    Autodesk.Viewing.Document.load(documentId, onDocumentLoadSuccess, onDocumentLoadFailure);

    function onDocumentLoadSuccess(viewerDocument) {
      var defaultModel = viewerDocument.getRoot().getDefaultGeometry();
      viewer.loadDocumentNode(viewerDocument, defaultModel);
    }

    function onDocumentLoadFailure() {
      console.error('Failed fetching Forge manifest');
    }
  });
}

export { launchViewer, filterModelBySetorBM };
