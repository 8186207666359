import { forwardRef } from 'react';
import { Spinner } from '../Spinner';
import { cn } from '../../lib/utils';

function Card({ title, value, isLoading, className }, ref) {
  return (
    <div
      className={cn(
        'text-white rounded flex flex-col gap-2 items-center justify-center text-center p-3',
        className
      )}
      ref={ref}>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <span className="font-semibold">{title}</span>
          <strong className="text-4xl text-plugSpecial">{value}</strong>
        </>
      )}
    </div>
  );
}

export default forwardRef(Card);
