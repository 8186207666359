import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';

function formatDate(date) {
  if (date) {
    return new Intl.DateTimeFormat('pt-BR').format(new Date(date));
  } else {
    return '';
  }
}

const columns = [
  {
    field: 'Plano_de_Acao',
    renderHeader: (params) => <div style={{ textAlign: 'center' }}>Plano de Ação</div>,
    width: window.innerWidth * 0.1
  },
  {
    field: 'Checklist',
    renderHeader: (params) => <div style={{ textAlign: 'center' }}>Tipo</div>,
    type: 'string',
    width: window.innerWidth * 0.2
  },
  {
    field: 'Item',
    renderHeader: (params) => <div style={{ textAlign: 'center' }}>Descrição</div>,
    type: 'string',
    width: window.innerWidth * 0.2
  },
  {
    field: 'Status',
    renderHeader: (params) => <div style={{ textAlign: 'center' }}>Status</div>,

    type: 'string',
    width: window.innerWidth * 0.1,
    valueGetter: (params) => params.row.Status
  },
  {
    field: 'Data_Solucao',
    renderHeader: (params) => <div style={{ textAlign: 'center' }}>Data de Solução</div>,
    type: 'string',
    width: window.innerWidth * 0.1,
    // valueGetter: (params) => params.row.Data_Solucao,
    valueGetter: (params) => formatDate(params.row.Data_Solucao)
  },
  {
    field: 'Solucao',
    renderHeader: (params) => <div style={{ textAlign: 'center' }}>Solução</div>,

    type: 'string',
    width: window.innerWidth * 0.2,
    valueGetter: (params) => params.row.Solucao
  },
  {
    field: 'Solucao_DataAnalise',
    renderHeader: (params) => <div style={{ textAlign: 'center' }}>Data de Análise</div>,

    type: 'string',
    width: window.innerWidth * 0.1,
    // valueGetter: (params) =>
    //   new Intl.DateTimeFormat('pt-BR').format(new Date(params.row.Solucao_DataAnalise))
    valueGetter: (params) => formatDate(params.row.Solucao_DataAnalise)
  },
  {
    field: 'Data_Conclusao',
    headerName: 'Data de Correção',
    renderHeader: (params) => <div style={{ textAlign: 'center' }}>Data de Conclusão</div>,
    type: 'string',
    width: window.innerWidth * 0.1,
    // valueGetter: (params) => params.row.Data_Conclusao
    valueGetter: (params) => formatDate(params.row.Data_Conclusao)
  }
];

export function TabelaPlanoDeAcao({ data }) {
  return (
    <Box sx={{ height: '300px', width: '100%', color: 'white' }}>
      <DataGrid
        style={{ color: '#fff' }}
        rows={data}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10
            }
          }
        }}
        pageSizeOptions={[10]}
        disableRowSelectionOnClick
      />
    </Box>
  );
}
