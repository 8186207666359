import Bars3Icon from '@heroicons/react/24/outline/Bars3Icon';
import XMarkIcon from '@heroicons/react/24/outline/XMarkIcon';

import logoPlug from '../assets/logo/logoPlug.png';

export function Header({ children, menuOpen, setMenuOpen }) {

  return (
    <header className={`bg-plugSecondary h-16 flex items-center justify-between px-4 fixed top-0 left-0 right-0 z-50`}>
      <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
          <img src={logoPlug} alt="PLUG" className="h-12" />
      </div>

      <div className="hidden lg:flex gap-2 items-center text-white">{children}</div>

      <button onClick={() => setMenuOpen(true)} className="flex lg:hidden">
        <Bars3Icon width={28} height={28} color="white" />
      </button>

      {menuOpen && (
        <div className={`fixed inset-0 lg:hidden bg-plugSecondary px-4 py-3 z-[100]`}>
          <header className="flex justify-between items-center">
            <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
              <img src={logoPlug} alt="PLUG" className="h-12" />
            </div>
            <button onClick={() => setMenuOpen(false)} className="flex lg:hidden">
              <XMarkIcon width={28} height={28} color="white" />
            </button>
          </header>

          <div className="flex flex-col gap-5 mt-8">{children}</div>
        </div>
      )}
    </header>
  );
}

export default Header;
