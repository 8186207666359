
import { cn } from '../../lib/utils';

import React from 'react';
import { Avatar, Box, CardContent, Stack, Typography, Tooltip } from '@mui/material';
import iconBar from '../../assets/icons/barsChart.png';
import { Spinner } from '../Spinner';

export function AvancoFinanceiroCard({ label, value, className, isLoading }) { 

    let acumulado = 0;
    let previsto = 100;
    let medido = 0;

    try {
      acumulado = parseFloat(value[value.length - 1].ValorAcumulado_WBS);
      previsto = parseFloat(value[value.length - 1].ValorPrevisto_WBS);
      medido = parseFloat(value[value.length - 1].Total_Previsto_Ate_Atual);
    } catch (error) {}

    const avancoFinanceiro = parseFloat(((acumulado / previsto) * 100).toFixed(2));
    const avancoPrevisto = parseFloat(((medido / previsto) * 100).toFixed(2));

    const containerStyle = {
      position: 'relative',
      height: '7px', // Altura da barra
      width: '100%',
      backgroundColor: 'rgba(255, 255, 255)',
      borderRadius: '5px',
      overflow: 'hidden',
    };

    const financialStyle = {
      position: 'absolute',
      height: '100%',
      width: `${avancoFinanceiro}%`,
      backgroundColor: '#66c182', // Verde
      zIndex: 1, // Atrás do marcador
    };

    const markerStyle = {
      position: 'absolute',
      height: '100%',
      width: '3px', // Espessura do marcador
      backgroundColor: '#07457e', // Azul claro
      left: `${avancoPrevisto}%`,
      zIndex: 2, // Marcador por cima
    };

  return (
    <div
      className={cn(
        'text-white rounded h-full',
        isLoading && 'items-center justify-center h-[72px] flex',
        className
      )}>

        <>
          <CardContent>
          {isLoading ? (
            <Spinner />
          ) : (
            <>
            <Stack alignItems="flex-start" direction="row" justifyContent="space-between" spacing={1}>
              <Stack spacing={0}>
                <Typography gutterBottom variant="overline">
                  {label}
                </Typography>
                <Typography variant="h6">
                  {avancoFinanceiro}%
                </Typography>
              </Stack>
              <Avatar
                sx={{
                  backgroundColor: '#62C17E',
                  height: 56,
                  width: 56,
                }}
              >
                <img src={iconBar} alt="s" style={{ width: '40px' }} />
              </Avatar>
            </Stack>
            <Box sx={{ mt: 2, width: '100%' }}>
              <Box sx={{ mt: 1 }}>
                <div style={containerStyle}>
                <Tooltip title={<> {`Avanço Financeiro: ${avancoFinanceiro}%`} <br /> {`Avanço Previsto: ${avancoPrevisto}%`} </>} arrow>
                    <div style={financialStyle}></div>
                  </Tooltip>
                  <Tooltip title={`Avanço Previsto: ${avancoPrevisto}%`} arrow>
                    <div style={markerStyle}></div>
                  </Tooltip>
                </div>
              </Box>
            </Box>
            </>
          )}
          </CardContent>
        </>

    </div>
  );
}

