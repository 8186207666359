import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import HomeQualidadePB from './pages/PlugBase/Qualidade/Home';

import QualidadeBanabuiu from './pages/Banabuiu/Qualidade/Home';

import CVIQualidade from './pages/CVI/Qualidade/Home';

import FisicoBraskem from './pages/Braskem/FisicoFinanceiro/Home';
import QualidadeBraskem from './pages/Braskem/Qualidade/Home';
import FinanceiroBraskem from './pages/Braskem/Financeiro/Home';

import FinanceiroPlugBase from './pages/PlugBase/Financeiro/Home';

import FisicoAPM from './pages/APM/FisicoFinanceiro';

export default function AppRouter() {
  return (
    <Router>
      <Routes>
        <Route path="/banabuiu/qualidade" element={<QualidadeBanabuiu />} />

        <Route path="/cvi/qualidade" element={<CVIQualidade />} />

        <Route path="/plugbase/financeiro" element={<FinanceiroPlugBase />} />
        <Route path="/plugbase/qualidade" element={<HomeQualidadePB />} />

        <Route path="/braskem/fisico" element={<FisicoBraskem />} />
        <Route path="/braskem/qualidade" element={<QualidadeBraskem />} />
        <Route path="/braskem/financeiro" element={<FinanceiroBraskem />} />

        <Route path="/apm/fisico" element={<FisicoAPM />} />
      </Routes>
    </Router>
  );
}
